import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import HomeContent from '../components/ContentPages/HomeContent';

function HomePage({ data, location }) {
  const sanityContent = data.homepage;
  const openingHours = data.settings.openingHours;

  return (
    <Layout location={location} lang="no">
      <HomeContent sanityContent={sanityContent} openingHours={openingHours} lang="No" />
    </Layout>
  );
}

export const query = graphql`
  query HomePageNo {
    homepage: sanityPage(id: { eq: "-7f0ee0c2-72bd-5edf-adc5-16642a70ae12" }) {
      id
      titleNo
      description {
        _rawTextNo
      }
      mainImage {
        asset {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
        alt
      }
      footerImage {
        asset {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
        alt
      }
      contentSections {
        ... on SanityContentSection {
          titleNo
          descriptionNo
          cta {
            ctaNo
            link {
              href
            }
          }
          image {
            asset {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
            alt
          }
        }
      }
    }
    settings: sanitySiteSettings {
      openingHours {
        _rawTextNo
      }
    }
  }
`;

export default HomePage;
